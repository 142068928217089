import { Component, Input } from '@angular/core';
import { Icon } from '@app/core/models/icon.model';

@Component({
  selector: 'qr-icon',
  templateUrl: './qr-icon.component.html',
  styleUrls: ['./qr-icon.component.scss'],
  standalone: true,
})
export class QrIconComponent {
  @Input() config?: Icon;
}
